import type { PropsWithChildren, ReactNode } from "react";

interface Props extends PropsWithChildren<unknown> {
  title: ReactNode;
  subtitle?: ReactNode;
  subtitleBelow?: ReactNode;
  prefix?: string;
  small?: boolean;

  docTitle?: true | string;
  className?: string;
}

export default function Heading({
  title,
  docTitle,
  prefix,
  subtitle,
  subtitleBelow,
  className = "",
  small,
  children
}: Props) {
  const Tag = small ? "h4" : "h3";

  return (
    <>
      <div
        className={`flex flex-wrap items-center justify-between ${
          small ? "mt-0" : "mt-4"
        } mb-4 border-b border-gray-200 pb-4 ${className}`}
      >
        <div className="flex-1">
          <Tag className="m-0 flex-1">
            {title}
            {subtitle && (
              <>
                {" "}
                <span className="text-2xl text-gray-500 print:mt-4 print:block">
                  {subtitle}
                </span>
              </>
            )}
          </Tag>
          {subtitleBelow && (
            <div className="mt-4 text-gray-600">{subtitleBelow}</div>
          )}
        </div>
        <div
          className={`flex items-center justify-between space-x-2 ${
            // Prevent button jump
            small ? "" : "min-h-[28px] print:min-h-0"
          }`}
        >
          {children}
        </div>
      </div>
    </>
  );
}
